import React from 'react';
import './App.css';
import MidiWriter from 'midi-writer-js';
import lamejs from 'lamejs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const { useState } = React;
const { useEffect } = React;
const { useRef } = React;

const notes = [
  "Do",
  "Do#",
  "Re",
  "Re#",
  "Mi",
  "Fa",
  "Fa#",
  "Sol",
  "Sol#",
  "La",
  "La#",
  "Si",
];

const scaleQualities = ["Major", "Minor", "Pent Maj", "Pent Min", "Gypsy", "Blues", "Haw", "Jap", "Egy", "Harm Min"]; 
const chordQualities = ["Major", "Minor", "Sus2", "Sus4", "Maj7", "Aug", "Dim", "Dim7", "m7b5", "Min7","mM7", "Dom7", "Aug7", "Add9", "Add4", "Add6"]; 

// Get the index of a note within an octave
const getIndexInOctave = (note) => {
  return notes.indexOf(note);
};

// Define intervals for different chord qualities
const chordIntervals = {
  Major: [4, 3],
  Minor: [3, 4],
  Sus2: [2, 5],
  Sus4: [5, 2],
  Maj7: [4, 3, 4],
  Aug: [4, 4],
  Dim: [3, 3],
  Dim7: [3, 3, 3],
  m7b5: [3, 3, 4],
  Min7: [3, 4, 3],
  mM7: [3, 4, 4],
  Dom7: [4, 3, 3],
  Aug7: [4, 4, 2],
  Add9: [4, 3, 7],
  Add4: [4, 1, 2],
  Add6: [4, 3, 2],
};

// Define intervals for different scale qualities
const scaleIntervals = {
  "Major": [2, 2, 1, 2, 2, 2, 1],
  "Minor": [2, 1, 2, 2, 1, 2, 2],
  "Pent Maj": [2, 2, 3, 2, 3],
  "Pent Min": [3, 2, 2, 3, 2],
  "Major": [2, 2, 1, 2, 2, 2, 1],
  "Minor": [2, 1, 2, 2, 1, 2, 2],
  "Gypsy": [1, 3, 1, 2, 1, 3, 1],
  "Blues": [3, 2, 1, 1, 3, 2],
  "Haw": [2, 1, 2, 2, 2, 2, 1],
  "Jap": [1, 4, 2, 3, 2],
  "Egy": [1, 3, 1, 2, 1, 2, 2],
  "Harm Min": [2, 1, 2, 2, 1, 3, 1]
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const DraggableChord = ({ chord, handleDragStart, buttonColor }) => {
  const cellClass = buttonColor === 'red' ? 'incompatible' : 'draggable-chord';

  // Usar la función isMobileDevice para condicionar el atributo draggable
  const draggable = !isMobileDevice();

  return (
    <td
      draggable={draggable} // Solo permitir arrastre en escritorio
      onDragStart={draggable ? (e) => handleDragStart(e, chord) : null} // Añadir el manejador condicionalmente
      className={cellClass}
    >
      {chord}
    </td>
  );
};

// Generate a scale based on a root note and quality
const generateScale = (rootNote, quality) => {
  let scale = [rootNote];
  let intervals = scaleIntervals[quality];
  let currentNote = rootNote;

  intervals.forEach((interval) => {
    currentNote = getNoteAtInterval(currentNote, interval);
    scale.push(currentNote);
  });

  return scale;
};

const getNoteAtInterval = (startNote, interval) => {
  let startIndex = getIndexInOctave(startNote);
  let targetIndex = (startIndex + interval) % notes.length;
  return notes[targetIndex];
};

// Generate a chord based on a root note and quality
const generateChord = (rootNote, quality) => {
  let chord = [rootNote];
  let intervals = chordIntervals[quality];
  let currentNote = rootNote;

  intervals.forEach((interval) => {
    currentNote = getNoteAtInterval(currentNote, interval);
    chord.push(currentNote);
  });

  return chord;
};

// Generate all major and minor scales
let generatedScales = {};
notes.forEach((note) => {
  scaleQualities.forEach((quality) => {
    let scaleName = `${note} ${quality}`;
    generatedScales[scaleName] = generateScale(note, quality);
  });
});

// Generate all chords
let generatedChords = {};
notes.forEach((note) => {
  chordQualities.forEach((quality) => {
    let chordName = `${note} ${quality}`;
    generatedChords[chordName] = generateChord(note, quality);
  });
});

// Check if a chord is compatible with a scale
const isChordCompatibleWithScale = (chord, scale) => {
  if (!generatedChords[chord] || !generatedScales[scale]) {
    return false;
  }

  return generatedChords[chord].every((note) =>
    generatedScales[scale].includes(note)
  );
};

const noteToMidi = {
  "Do": "C4",
  "Do#": "C#4",
  "Re": "D4",
  "Re#": "D#4",
  "Mi": "E4",
  "Fa": "F4",
  "Fa#": "F#4",
  "Sol": "G4",
  "Sol#": "G#4",
  "La": "A4",
  "La#": "A#4",
  "Si": "B4",
};

const createMidiFile = (chords) => {
  let track = new MidiWriter.Track();

  chords.forEach(chord => {
    // Aquí debes convertir el nombre del acorde en notas MIDI
    // Esta es una parte compleja, ya que debes mapear los acordes a sus notas correspondientes
    // Por ejemplo:
    let midiNotes = convertChordToMidi(chord);
    let noteEvent = new MidiWriter.NoteEvent({ pitch: midiNotes, duration: '4' });
    track.addEvent(noteEvent);
  });

  let write = new MidiWriter.Writer(track);
  return write.dataUri();
};

const convertChordToMidi = (chord) => {
  let [rootNote, quality] = chord.split(" ");
  let midiNotes = [noteToMidi[rootNote]];
  let intervals = chordIntervals[quality];
  
  let currentNoteMidi = midiNoteToNumber(midiNotes[0]);

  intervals.forEach(interval => {
    currentNoteMidi += interval;
    midiNotes.push(midiNumberToNote(currentNoteMidi));
  });

  return midiNotes;
};

const midiNoteToNumber = (note) => {
  const noteToNumber = {
    "C": 0,
    "C#": 1,
    "D": 2,
    "D#": 3,
    "E": 4,
    "F": 5,
    "F#": 6,
    "G": 7,
    "G#": 8,
    "A": 9,
    "A#": 10,
    "B": 11
  };

  const baseMidi = 60; // MIDI number for C4
  const noteLetter = note.substring(0, note.length - 1);
  const octave = parseInt(note[note.length - 1], 10);

  return baseMidi + noteToNumber[noteLetter] + 12 * (octave - 4);
};

const midiNumberToNote = (number) => {
  const numberToNote = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
  const octave = Math.floor(number / 12) - 1;
  const noteIndex = number % 12;

  return numberToNote[noteIndex] + octave;
};

/**
 * Gets incompatibles between chords and scales.
 *
 * @template {string[]} T Collection of chords or scales.
 * @template {string[]} U Collection of chords or scales.
 *
 * @param {T} a Collection of chords or scales.
 * @param {U} b The other collection not given for `a`.
 * @param {'chord'|'scale'} aIs Identifies which collection is given for `a`.
 * @param {'some'|'every'} op The comparison function for evaluating how the collection booleans should be joined.
 * @return {T} Filtered collection `a` that are incompatible with `b`.
 */

const getIncompatibles = (a, b, aIs, op) =>
  a.filter((aValue) =>
    b[op](
      (bValue) =>
        !isChordCompatibleWithScale(
          aIs === "chord" ? aValue : bValue,
          aIs === "chord" ? bValue : aValue
        )
    )
  );
  
  // Función para convertir el nombre del acorde en una ruta de archivo de sonido
  const getSoundFilePath = (chordName) => {
    return `/Chords/${chordName.replace(/\s+/g, '-').replace(/#/g, 'S')}.mp3`;
  };

  const getScaleSoundFilePath = (scaleName) => {
    return `/Scales/${scaleName.replace(/\s+/g, '-').replace(/#/g, 'S')}.mp3`;
  };  

  // Función para reproducir el sonido de una escala
  const playScaleSound = (scale) => {
    const soundFile = getScaleSoundFilePath(scale);
    const audio = new Audio(soundFile);
    audio.play();
  };

const App = () => {
  const [selectedChords, setSelectedChords] = useState([]);
  const [selectedScales, setSelectedScales] = useState([]);

  const [draggedChord, setDraggedChord] = useState(null);
  const [droppedChords, setDroppedChords] = useState([]);

  const [playBarPosition, setPlayBarPosition] = useState(-1);  // Cambiado a -1
  const [isPlaying, setIsPlaying] = useState(false);

  const [contextMenu, setContextMenu] = useState({
    mouseX: null,
    mouseY: null,
    visible: false,
  });

  const [selectedNote, setSelectedNote] = useState({ name: null, notes: '' });

  const [saveMenu, setSaveMenu] = useState({ visible: false, x: 0, y: 0 });

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState({ left: 0, top: 0 });

  const menuIconRef = useRef(null);

  const menuRef = useRef(null);

  const [theme, setTheme] = useState('dark'); 

  const [notationType, setNotationType] = useState('DoReMi');

  const [isContactPopupVisible, setIsContactPopupVisible] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [bpm, setBpm] = useState(70); // 120 es un valor inicial predeterminado para BPM, pero puedes ajustarlo según tus necesidades

  const [lastPlayedAudio, setLastPlayedAudio] = useState(null);

  // Ejemplo de uso en la función playChordSound
  const playChordSound = (chord) => {
    // Si hay un acorde anterior reproduciéndose, detenlo
    if (lastPlayedAudio) {
      lastPlayedAudio.pause();
      lastPlayedAudio.currentTime = 0; // Reinicia el tiempo del audio para una futura reproducción
    }
  
    const soundFile = getSoundFilePath(chord);
    const audio = new Audio(soundFile);
    audio.play();
  
    // Actualiza la referencia al último `Audio` reproducido
    setLastPlayedAudio(audio);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    // Configura el estado de `anchorEl` al elemento del evento, que sirve como ancla para el menú
    setAnchorEl(event.currentTarget);
  };

  const openContactPopup = () => {
    setIsContactPopupVisible(true);
  };
  
  const closeContactPopup = () => {
    setIsContactPopupVisible(false);
  };

  const mapNotation = (note) => {
    const mapping = {
      'Do': 'C', 'Do#': 'C#', 'Re': 'D', 'Re#': 'D#', 'Mi': 'E',
      'Fa': 'F', 'Fa#': 'F#', 'Sol': 'G', 'Sol#': 'G#', 'La': 'A',
      'La#': 'A#', 'Si': 'B',
      'C': 'Do', 'C#': 'Do#', 'D': 'Re', 'D#': 'Re#', 'E': 'Mi',
      'F': 'Fa', 'F#': 'Fa#', 'G': 'Sol', 'G#': 'Sol#', 'A': 'La',
      'A#': 'La#', 'B': 'Si'
    };
    return mapping[note] || note; // Esto devolverá la nota mapeada o la original si no se encuentra en el objeto de mapeo.
  };

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
    setIsMenuVisible(false);
    document.body.className = newTheme === 'dark' ? '' : 'light-mode'; // Cambia la clase del body
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme') || 'dark';
    setTheme(currentTheme);
    document.body.className = currentTheme === 'dark' ? '' : 'light-mode';
  }, []);
  
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const changeTab = (tabName) => {
    setActiveTab(tabName);
  };

  const [activeTab, setActiveTab] = useState('Tutorial'); // Iniciar con el tab Tutorial

   // Función para manejar clics fuera del menú
   const handleClickOutside = (event) => {
    // Comprueba si el clic fue fuera del menú Y no fue en el ícono del menú
    if (menuRef.current && !menuRef.current.contains(event.target) && 
        !menuIconRef.current.contains(event.target)) {
        setIsMenuVisible(false); // Oculta el menú si el clic es fuera y no es en el ícono del menú
    }
  };

  // Efecto para agregar y remover el manejador de evento
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Función para cerrar el menú desplegable
  const handleCloseMenu = () => {
    setIsMenuVisible(false);
  };

  const toggleMenu = (event) => {
    // Detiene la propagación del evento para evitar que active el manejador handleClickOutside
    event.stopPropagation();

    // Cambia el estado de visibilidad del menú
    setIsMenuVisible(!isMenuVisible);

    // Configura la posición del menú solo si va a ser visible
    if (!isMenuVisible) {
        const rect = event.currentTarget.getBoundingClientRect();
        setDropdownPosition({
            left: rect.left + rect.width / 2, // Centro horizontalmente
            top: rect.bottom, // Justo debajo del botón
        });
    }
};

  const addToBar = (displayChordName) => {
    // La variable `notationType` determina el modo de notación actual. Asegúrate de que esta variable esté correctamente actualizada en el estado de tu componente.
    
    // Convierte el nombre del acorde de la notación de visualización actual a la notación interna "DoReMi" si es necesario.
    let internalChordName;
    let displayName;

    if (notationType === 'DoReMi') {
      // Si el modo actual es "CDE", convierte el nombre del acorde a "DoReMi" para uso interno
      internalChordName = convertToInternalNotation(displayChordName);
      // Pero mantén el nombre para mostrar como está, ya que queremos que se muestre en "CDE"
      displayName = displayChordName;
    } else {
      // Si el modo actual es "DoReMi", no es necesario convertir el nombre del acorde.
      internalChordName = displayChordName;
      displayName = convertToDisplayNotation(displayChordName, 'CDE'); // Asegúrate de que esta función convierta correctamente de "DoReMi" a "CDE"
    }

    // Agrega el acorde a la barra con su nombre interno y el nombre para mostrar.
    setDroppedChords(prevChords => [
      ...prevChords,
      { internalName: internalChordName, displayName: displayName }
    ]);
  };

  const handleRightClick = (e, combination, type) => {
    e.preventDefault();
    let notesToShow;
  
    // Determinar si el clic derecho fue sobre un acorde o una escala
    if (type === "chord" && generatedChords.hasOwnProperty(combination)) {
      notesToShow = generatedChords[combination];
    } else if (type === "scale" && generatedScales.hasOwnProperty(combination)) {
      notesToShow = generatedScales[combination];
    } else {
      notesToShow = ['N/A'];
    }
  
    // Determinar la función de reproducción adecuada (acorde o escala)
    const playAction = type === "chord" ? () => playChordSound(combination) : () => playScaleSound(combination);
  
    setSelectedNote({
      name: combination,
      notes: notesToShow.join(', '),
      play: playAction, // Almacenar la función de reproducción adecuada
      addToBar: addToBar, // Actualizado para asignar la función directamente
      type: type
    });
  
    setContextMenu({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
      visible: true,
    });
  };        

  const closeContextMenu = () => {
    setContextMenu({
      ...contextMenu,
      visible: false,
    });
  };

  useEffect(() => {
    // Verifica si el tab activo NO es 'Tutorial' antes de agregar el manejador de eventos
    if (activeTab !== 'Tutorial') {
        const closeContextMenuIfClickedOutside = (event) => {
            closeContextMenu();
        };

        window.addEventListener('click', closeContextMenuIfClickedOutside);
        return () => {
            window.removeEventListener('click', closeContextMenuIfClickedOutside);
        };
    }
}, [activeTab, closeContextMenu]);

  const preloadAudioFiles = (chords) => {
    const audioFiles = chords.map(chord => {
      const audio = new Audio(getSoundFilePath(chord));
      return new Promise((resolve) => {
        audio.addEventListener('canplaythrough', () => resolve(audio), { once: true });
      });
    });
  
    return Promise.all(audioFiles);
  };

  const handlePlayClick = () => {
    if (!isPlaying && droppedChords.length > 0) {
      setIsPlaying(true);
      setPlayBarPosition(0);
  
      // Precargar los archivos de audio
      preloadAudioFiles(droppedChords.map(chord => chord.internalName)).then(audioFiles => {
        // Una vez que todos los archivos están precargados, comenzar la reproducción
        audioFiles.forEach((audio, index) => {
          // Calcular el tiempo de espera basado en el BPM
          // 60,000 ms en un minuto dividido por el BPM da el tiempo por beat (ms por beat)
          // Ajustar este valor según sea necesario para sincronizar con la duración del acorde
          const waitTime = 60000 / bpm; // Asume que cada acorde dura un beat
  
          setTimeout(() => {
            audio.play();
            setPlayBarPosition(index);
  
            if (index === droppedChords.length - 1) {
              setTimeout(() => {
                setIsPlaying(false);
                setPlayBarPosition(-1);
              }, waitTime); // Esperar según el BPM antes de cambiar el estado para el último acorde
            }
          }, waitTime * index); // Esperar según el BPM entre cada acorde
        });
      });
    }
  };   

  // Paso 1: Refactorizar la eliminación de acordes para asegurar la consistencia del estado
  const handleDropOnTrashBin = (e) => {
    e.preventDefault();
    // Filtra los acordes para eliminar el arrastrado
    const newDroppedChords = droppedChords.filter(chordObj => chordObj.internalName !== draggedChord);
    setDroppedChords(newDroppedChords);

    // Verifica y actualiza visualizaciones de forma condicional
    updateVisualizationsPostRemoval(newDroppedChords);
  };

  // Paso 2 y 3: Actualización segura del estado y revisión de la lógica de actualización de imágenes
  const updateVisualizationsPostRemoval = (updatedDroppedChords) => {
    // Verifica si la visualización de acordes para guitarra o piano está activa y actualiza según sea necesario
    if (guitarChordsVisible) {
        setGuitarChordImages(generateChordImages(updatedDroppedChords, 'guitar'));
    }
    if (pianoChordsVisible) {
        setPianoChordImages(generateChordImages(updatedDroppedChords, 'piano'));
    }
  };

  const generateChordImages = (chords, instrument) => {
    return chords.map(chordObj => {
        const chordFileName = chordObj.internalName.replace(/\s+/g, '-').replace(/#/g, 'S') + '.png';
        return `/Chords-${instrument}/${chordFileName}`;
    });
  };

  //--------------------------------↓↓ Reordenamiento ↓↓--------------------------------//

  const calculateSegmentBasedDropIndex = (dropX) => {
    const dropBar = document.querySelector('.drop-bar');
    const dropBarRect = dropBar.getBoundingClientRect();
    const relativeX = dropX - dropBarRect.left;
    
    // Define el número de segmentos como el número actual de acordes más uno para el espacio potencial de inserción
    const numSegments = droppedChords.length + 1;
    const segmentWidth = dropBarRect.width / numSegments;
    
    // Calcula el índice del segmento más cercano a la posición del cursor
    let segmentIndex = Math.floor(relativeX / segmentWidth);
    if (relativeX === dropBarRect.width) {
        segmentIndex = droppedChords.length;
    }
    
    // Ajusta el índice para evitar desbordamientos
    segmentIndex = Math.max(0, Math.min(segmentIndex, droppedChords.length));
    updateDropHighlight(-1);
    return segmentIndex;
  };

  const handleDragStartOnBar = (e, chord) => {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', chord);
    setDraggedChord(chord);
  };
  
  const handleDropOnBar = (e) => {
    e.preventDefault();
    // Obtener el nombre interno del acorde arrastrado desde el evento de transferencia de datos
    const draggedChordInternalName = e.dataTransfer.getData('text');

    // Encontrar el objeto de acorde arrastrado en base a su nombre interno
    const draggedChordObj = droppedChords.find(chord => chord.internalName === draggedChordInternalName);

    // Verificar que el objeto del acorde arrastrado exista
    if (!draggedChordObj) return;

    // Crear una nueva lista de acordes excluyendo el acorde arrastrado
    const newDroppedChords = droppedChords.filter(chord => chord.internalName !== draggedChordInternalName);

    // Calcular el índice de inserción basado en la posición del cursor
    const dropIndex = calculateSegmentBasedDropIndex(e.clientX);

    // Insertar el objeto del acorde arrastrado en la nueva posición
    newDroppedChords.splice(dropIndex, 0, draggedChordObj);

    // Actualizar el estado con la nueva lista de acordes
    setDroppedChords(newDroppedChords);

    // Limpia cualquier resaltado después de soltar
    updateDropHighlight(-1); // Asume que -1 es un código para 'limpiar todo'
  };

  const updateDropHighlight = (dropIndex) => {
    // Primero, limpia cualquier resaltado anterior
    const existingHighlights = document.querySelectorAll('.drop-highlight');
    existingHighlights.forEach(el => el.classList.remove('drop-highlight'));
  
    // No intentar añadir un nuevo resaltado si el índice es -1
    if (dropIndex === -1) {
      return; // Simplemente retorna aquí para evitar añadir un nuevo resaltado
      console.log("Test");
    }
  
    // Luego, añade el resaltado en la nueva posición si el índice no es -1
    const dropBar = document.querySelector('.drop-bar');
    const chords = dropBar.children; // Asumiendo que cada acorde es un hijo directo de '.drop-bar'
    
    if (dropIndex < chords.length) {
      chords[dropIndex].classList.add('drop-highlight');
    } else if (chords.length > 0) {
      // Si es el último elemento, añade el resaltado al último acorde
      chords[chords.length - 1].classList.add('drop-highlight');
    }
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    
    // Encuentra el índice donde se insertará el acorde
    const dropIndex = calculateSegmentBasedDropIndex(e.clientX, draggedChord);
    
    // Actualiza la visualización de la barra para mostrar dónde se insertará el acorde
    updateDropHighlight(dropIndex);
  };

  //--------------------------------↑↑ Reordenamiento ↑↑--------------------------------//

  // Modifica la generación de acordes en la barra
  const generateDroppedChords = () => {
    return droppedChords.map((chordObj, index) => (
      <div 
        key={index} 
        className={`dropped-chord ${isPlaying && index === playBarPosition && playBarPosition !== -1 ? 'active-chord' : ''}`}
        draggable
        onDragStart={(e) => handleDragStartOnBar(e, chordObj.internalName)} // Usa internalName para la lógica interna
        onDrop={handleDropOnBar}
        onDragOver={handleDragOver}
      >
        {chordObj.displayName}
      </div>
    ));
  };  

  const handleDragStart = (e, chord) => {
    if (isMobileDevice()) {
      // En dispositivos móviles, prevenir cualquier acción de arrastre
      e.preventDefault();
    } else {
      // En escritorio, configurar los datos para el arrastre
      e.dataTransfer.setData('text/plain', chord);
      e.dataTransfer.setData('isNewChord', true); // Indicar que es un nuevo acorde
      setDraggedChord(chord);
    }
  };

  const convertToInternalNotation = (chordName) => {
    const mapping = {
      'C': 'Do', 'C#': 'Do#', 'D': 'Re', 'D#': 'Re#', 'E': 'Mi',
      'F': 'Fa', 'F#': 'Fa#', 'G': 'Sol', 'G#': 'Sol#', 'A': 'La',
      'A#': 'La#', 'B': 'Si'
    };
  
    // Dividir el nombre del acorde para obtener la nota y la calidad por separado
    const parts = chordName.split(" ");
    if (parts.length > 1) {
      const [note, ...qualityParts] = parts;
      const quality = qualityParts.join(" ");
      // Convertir solo la nota, mantener la calidad del acorde igual
      const internalNote = mapping[note] || note;
      return `${internalNote} ${quality}`;
    }
  
    return chordName; // Retornar el nombre original si no se puede dividir
  };

  const convertToDisplayNotation = (chordName, targetNotation) => {
    const toDoReMi = {
      'C': 'Do', 'C#': 'Do#', 'D': 'Re', 'D#': 'Re#', 'E': 'Mi',
      'F': 'Fa', 'F#': 'Fa#', 'G': 'Sol', 'G#': 'Sol#', 'A': 'La',
      'A#': 'La#', 'B': 'Si'
    };
    const toCDE = {
      'Do': 'C', 'Do#': 'C#', 'Re': 'D', 'Re#': 'D#', 'Mi': 'E',
      'Fa': 'F', 'Fa#': 'F#', 'Sol': 'G', 'Sol#': 'G#', 'La': 'A',
      'La#': 'A#', 'Si': 'B'
    };
  
    // Determinar el mapeo basado en la notación objetivo
    const mapping = targetNotation === 'DoReMi' ? toDoReMi : toCDE;
  
    // Dividir el nombre del acorde en nota y calidad
    const parts = chordName.split(" ");
    if (parts.length > 1) {
      const [note, ...qualityParts] = parts;
      const quality = qualityParts.join(" ");
      const displayNote = mapping[note] || note; // Convertir la nota usando el mapeo apropiado
      return `${displayNote} ${quality}`;
    } else {
      return mapping[chordName] || chordName;
    }
  };

  const updateChordDisplayNames = (notation) => {
    // Mapear sobre los acordes sueltos para actualizar su displayName basado en la nueva notación
    const updatedChords = droppedChords.map(chordObj => {
      const newDisplayName = convertToDisplayNotation(chordObj.internalName, notation); // Asumiendo que esta función ahora también acepta la notación como un argumento
      return { ...chordObj, displayName: newDisplayName };
    });
  
    // Actualizar el estado de droppedChords con los nuevos displayNames
    setDroppedChords(updatedChords);
  };
  
  // Lugar donde el usuario cambia la notación
  const handleNotationToggle = () => {
    const newNotation = notationType === 'DoReMi' ? 'CDE' : 'DoReMi';
    handleNotationChange(newNotation);
  };

  // Actualiza los nombres de los acordes en la barra según la notación seleccionada
  const handleNotationChange = (newNotation) => {
    setNotationType(newNotation); // Actualiza la notación actual
    // Actualiza el displayName de cada acorde en droppedChords
    const updatedChords = droppedChords.map(chordObj => {
      const newDisplayName = convertToDisplayNotation(chordObj.internalName, newNotation);
      return { ...chordObj, displayName: newDisplayName };
    });
    setDroppedChords(updatedChords); // Establece los acordes actualizados en el estado
    handleCloseMenu(); // Cierra el menú desplegable
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const chordName = e.dataTransfer.getData('text');
    const isNewChord = e.dataTransfer.getData('isNewChord') === 'true';
  
    // Convierte el nombre del acorde a notación interna si la notación actual es "CDE"
    let internalChordName = notationType === 'CDE' ? convertToInternalNotation(chordName) : chordName;
    let displayChordName = notationType === 'DoReMi' ? chordName : convertToDisplayNotation(chordName);
  
    const chordObject = {
      internalName: internalChordName, // Usado para lógica interna y funcionalidades
      displayName: displayChordName, // Usado para mostrar al usuario
    };
  
    if (isNewChord) {
      const dropIndex = calculateSegmentBasedDropIndex(e.clientX);
      const newDroppedChords = [...droppedChords];
      // Insertar el nuevo objeto de acorde en la posición calculada
      newDroppedChords.splice(dropIndex, 0, chordObject);
      setDroppedChords(newDroppedChords);
    } else {
      // Manejar reordenamiento en la barra
      const newDroppedChords = [...droppedChords];
      const draggedIndex = newDroppedChords.findIndex(chord => chord.internalName === chordObject.internalName);
      if (draggedIndex >= 0) {
        newDroppedChords.splice(draggedIndex, 1);
        const dropIndex = calculateSegmentBasedDropIndex(e.clientX);
        newDroppedChords.splice(dropIndex, 0, chordObject);
        setDroppedChords(newDroppedChords);
      }
    }
  }; 

  const handleSaveClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  
    // Verifica si el saveMenu ya está visible
    if (saveMenu.visible) {
      // Si está visible, simplemente cierra el menú
      setSaveMenu({
        ...saveMenu,
        visible: false,
      });
    } else {
      // Si no está visible, calcula la posición y muestra el menú
      const rect = event.currentTarget.getBoundingClientRect();
  
      setSaveMenu({
        visible: true,
        x: rect.left,
        y: rect.bottom, // Ajusta para que aparezca justo debajo del elemento que dispara el evento
      });
    }
  };

  // Función para cerrar el menú de guardar
  const closeSaveMenu = () => {
    setSaveMenu({ visible: false, x: 0, y: 0 });
  };

  const downloadMidi = () => {
    // Extrae solo los nombres internos o de visualización de los acordes
    const chordNames = droppedChords.map(chordObj => chordObj.internalName);
    const midiDataUri = createMidiFile(chordNames); // Asegúrate de que createMidiFile maneje esto correctamente
    const element = document.createElement('a');
    element.setAttribute('href', midiDataUri);
    element.setAttribute('download', 'ChordProg.mid');
  
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

  //--------------------------------↓↓ MP3 ↓↓--------------------------------//

  function convertFloat32ToInt16(buffer) {
    let l = buffer.length;
    let buf = new Int16Array(l);
    while (l--) {
      buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
    }
    return buf;
  }
  
  const handleDownloadMP3 = async () => {
    const audioContext = new AudioContext();
    const buffers = [];
  
    for (const chordObj of droppedChords) {
      // Utiliza `internalName` para acceder al nombre que se usa en el sistema de archivos
      const response = await fetch(getSoundFilePath(chordObj.internalName));
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      buffers.push(audioBuffer);
    }

    const concatenatedBuffer = concatenateAudioBuffers(buffers, audioContext);
    const mp3Data = encodeAudioBufferToMP3(concatenatedBuffer);

    const blob = new Blob([new Uint8Array(mp3Data)], { type: 'audio/mp3' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ChordProg.mp3';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  function concatenateAudioBuffers(buffers, audioContext) {
    // Calcular la longitud total de los buffers
    let totalLength = buffers.reduce((acc, buffer) => acc + buffer.length, 0);

    // Crear un nuevo AudioBuffer con la longitud total
    let concatenatedBuffer = audioContext.createBuffer(
        buffers[0].numberOfChannels, totalLength, buffers[0].sampleRate
    );

    // Copiar los datos de cada buffer al buffer concatenado
    let offset = 0;
    buffers.forEach(buffer => {
        for (let i = 0; i < buffer.numberOfChannels; i++) {
            concatenatedBuffer.getChannelData(i).set(buffer.getChannelData(i), offset);
        }
        offset += buffer.length;
    });

    return concatenatedBuffer;
  }

  function encodeAudioBufferToMP3(audioBuffer) {
    let channels = audioBuffer.numberOfChannels;
    let sampleRate = audioBuffer.sampleRate;
    let kbps = 128; // Ejemplo de tasa de bits
    let mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
    let mp3Data = [];
  
    let samples = audioBuffer.length; // Define la variable samples aquí
    let buffer = [];
  
    // Convierte AudioBuffer a un formato que lamejs pueda entender
    let leftChannel = convertFloat32ToInt16(audioBuffer.getChannelData(0));
    let rightChannel = audioBuffer.numberOfChannels > 1 ?
                        convertFloat32ToInt16(audioBuffer.getChannelData(1)) :
                        leftChannel;

    for (let i = 0; i < samples; i += 1152) {
      buffer = mp3encoder.encodeBuffer(leftChannel.subarray(i, i + 1152), rightChannel.subarray(i, i + 1152));
      if (buffer.length > 0) {
        mp3Data.push(buffer);
      }
    }
  
    // Finalizar la codificación y obtener el buffer MP3
    buffer = mp3encoder.flush();
    if (buffer.length > 0) {
      mp3Data.push(buffer);
    }
  
    // Concatenar los buffers MP3 en un solo Uint8Array
    let mp3Buffer = new Uint8Array(mp3Data.reduce((acc, curr) => acc.concat(Array.from(curr)), []));
    return mp3Buffer;
  }
  
  //--------------------------------↑↑ MP3 ↑↑--------------------------------//


  //--------------------------------↓↓ Chords for guitar ↓↓--------------------------------//
  const [guitarChordImages, setGuitarChordImages] = useState([]);
  const [guitarChordsVisible, setGuitarChordsVisible] = useState(false);

  const visualizeGuitarChords = () => {
    if (guitarChordsVisible) {
      setGuitarChordImages([]); // Vaciar el array de imágenes para ocultarlas
    } else {
      updateGuitarChordImages(); // Actualizar las imágenes para mostrarlas
    }
    setGuitarChordsVisible(!guitarChordsVisible); // Alternar la visibilidad
    closeSaveMenu(); // Cierra el menú de guardado
  };

  // Función para actualizar las imágenes de los acordes de guitarra
  const updateGuitarChordImages = () => {
    const chordImages = droppedChords.map(chordObj => {
      // Usa `displayName` para generar el nombre del archivo de imagen.
      const chordFileName = chordObj.internalName.replace(/\s+/g, '-').replace(/#/g, 'S') + '.png';
      return `/Chords-Guitar/${chordFileName}`;
    });
    setGuitarChordImages(chordImages);
  };

  // Actualizar las imágenes de acordes de guitarra cuando se elimina un acorde
  useEffect(() => {
    if (guitarChordImages.length > 0) {
      updateGuitarChordImages();
    }
  }, [droppedChords]);

  //--------------------------------↑↑ Chords for guitar ↑↑--------------------------------//

  //--------------------------------↓↓ Chords for piano ↓↓--------------------------------//
  const [pianoChordImages, setPianoChordImages] = useState([]);
  const [pianoChordsVisible, setPianoChordsVisible] = useState(false);

  const visualizePianoChords = () => {
    if (pianoChordsVisible) {
      setPianoChordImages([]); // Vaciar el array de imágenes para ocultarlas
    } else {
      updatePianoChordImages(); // Actualizar las imágenes para mostrarlas
    }
    setPianoChordsVisible(!pianoChordsVisible); // Alternar la visibilidad
    closeSaveMenu(); // Cierra el menú de guardado
  };
  
  // Función para actualizar las imágenes de los acordes de piano
  const updatePianoChordImages = () => {
    const chordImages = droppedChords.map(chordObj => {
      const chordFileName = chordObj.internalName.replace(/\s+/g, '-').replace(/#/g, 'S') + '.png';
      return `/Chords-Piano/${chordFileName}`;
    });
    setPianoChordImages(chordImages);
  };
  
  useEffect(() => {
    if (pianoChordImages.length > 0) {
      updatePianoChordImages();
    }
  }, [droppedChords]);
  //--------------------------------↑↑ Chords for piano ↑↑--------------------------------//


  // Opciones del menú de guardar
  const saveOptions = [
    { label: "Download MIDI", action: downloadMidi },
    { label: "Download MP3", action: handleDownloadMP3 },
    {
      label: "Chords for guitar",
      action: visualizeGuitarChords,
      check: guitarChordsVisible // Añade un indicador de check
    },
    {
      label: "Chords for piano",
      action: visualizePianoChords,
      check: pianoChordsVisible // Añade un indicador de check
    },
    { label: "Close", action: closeSaveMenu }
  ];

  const handleSaveMenuClick = (e) => {
    e.stopPropagation(); // Esto evita que se cierre el menú al hacer clic dentro de él.
  };
  
  const handleDocumentClick = (e) => {
    setTimeout(() => {
      if (saveMenu.visible) {
        closeSaveMenu();
      }
    }, 10);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (saveMenu.visible) {
        closeSaveMenu();
      }
    };
  
    // Se agrega el manejador al documento
    document.addEventListener('click', handleDocumentClick);
    return () => {
      // Se asegura de remover el manejador cuando el componente se desmonte
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [saveMenu.visible]);

  // Handle click on a chord button
  const handleChordClick = (chord) => {
    setSelectedChords((chords) =>
      chords.includes(chord)
        ? chords.filter((c) => c !== chord)
        : chords.concat(chord)
    );
  };

  // Handle click on a scale button
  const handleScaleClick = (scale) => {
    setSelectedScales((scales) =>
      scales.includes(scale)
        ? scales.filter((s) => s !== scale)
        : scales.concat(scale)
    );
  };

  let incompatibleChords = selectedScales.length
    ? getIncompatibles(
        Object.keys(generatedChords),
        selectedScales,
        "chord",
        "some"
      )
    : [];

  let incompatibleScales = selectedChords.length
    ? getIncompatibles(
        Object.keys(generatedScales),
        selectedChords,
        "scale",
        "some"
      )
    : [];

  const maybeCompatibleChords = Object.keys(generatedChords).filter(
    (chord) => !incompatibleChords.includes(chord)
  );
  const maybeCompatibleScales = Object.keys(generatedScales).filter(
    (scale) => !incompatibleScales.includes(scale)
  );

  if (incompatibleScales.length) {
    incompatibleChords = incompatibleChords.concat(
      getIncompatibles(
        maybeCompatibleChords,
        maybeCompatibleScales,
        "chord",
        "every"
      )
    );
  }

  if (incompatibleChords.length) {
    incompatibleScales = incompatibleScales.concat(
      getIncompatibles(
        maybeCompatibleScales,
        maybeCompatibleChords,
        "scale",
        "every"
      )
    );
  }

  const generateRowCells = (type, rowQuality) => {
    return notes.map((note) => {
        // Nota: Aquí no necesitas convertir la nota, ya que la operación interna usa "DoReMi"
        const combination = `${note} ${rowQuality}`;
        const collection = type === "chord" ? selectedChords : selectedScales;
        // Determinar el color del botón basado en la compatibilidad y selección
        let buttonColor;
        if (theme === 'dark') {
            buttonColor = "#353537"; // Color oscuro predeterminado para el modo oscuro
        } else {
            buttonColor = "#FFFFFF"; // Color claro predeterminado para el modo claro
        }

        const compatibleColor = theme === 'light' ? "#4CAF50" : "#11823b";
        const incompatibleColor = theme === 'light' ? "#F94449" : "#a70000";

        if (collection.includes(combination)) {
          buttonColor = compatibleColor; // Usa el color basado en el tema actual
        } else if ((type === "chord" && incompatibleChords.includes(combination)) || (type === "scale" && incompatibleScales.includes(combination))) {
          buttonColor = incompatibleColor; // Color para los incompatibles, ajustado previamente
        }

        const onClick = buttonColor !== "#a70000"
            ? (type === "chord" ? () => handleChordClick(combination) : () => handleScaleClick(combination))
            : null;

        const draggable = type === "chord";
        const onContextMenu = (e) => handleRightClick(e, combination, type);

        // Convertir la nota para mostrarla solo si la notación actual es "CDE"
        const displayedNote = notationType === 'CDE' ? mapNotation(note) : note;

        return (
            <td
                key={note}
                onClick={onClick}
                onContextMenu={onContextMenu}
                style={{ backgroundColor: buttonColor, cursor: 'pointer' }}
                className={buttonColor === "#a70000" ? "incompatible" : "draggable-chord"}
                draggable={draggable}
                onDragStart={draggable ? (e) => handleDragStart(e, `${displayedNote} ${rowQuality}`) : null} // Asegúrate de pasar la nota convertida si es necesario
            >
                {`${displayedNote} ${rowQuality}`}
            </td>
        );
    });
  };   

  // Generate table rows for chords or scales
  const generateTableRows = (type) => {
    const qualities = type === "chord" ? chordQualities : scaleQualities;
    return qualities.map((quality) => (
      <tr key={quality}>
        <th>{quality}</th>
        {generateRowCells(type, quality)}
      </tr>
    ));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Verificar primero si hay acordes en la barra
      if (droppedChords.length > 0) {
        // Prevent default action to stop things like scrolling with space bar
        if (["Space", "KeyG", "KeyP", "Digit3"].includes(event.code)) {
          event.preventDefault();
        }
  
        // Play chords in bar with Space Bar
        if (event.code === "Space" && !isPlaying) {
          handlePlayClick();
        }
  
        // Visualize guitar chords with G
        if (event.code === "KeyG") {
          visualizeGuitarChords();
        }
  
        // Visualize piano chords with P
        if (event.code === "KeyP") {
          visualizePianoChords();
        }
  
        // Download MIDI with Ctrl+I
        if (event.ctrlKey && event.code === "KeyI") {
          downloadMidi();
        }
  
        // Download MP3 with Ctrl+3
        if (event.ctrlKey && event.code === "Digit3") {
          handleDownloadMP3();
        }
      }
    };
  
    // Add event listener
    window.addEventListener('keydown', handleKeyDown);
  
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [droppedChords.length, isPlaying, handlePlayClick, visualizeGuitarChords, visualizePianoChords, downloadMidi, handleDownloadMP3]); // Ensure to list all dependencies here
  
  const isSaveDisabled = droppedChords.length === 0;

  document.querySelectorAll('.chord-table th').forEach((cell) => {
    if (cell.textContent.trim() === 'Add6') {
      cell.style.borderBottomLeftRadius = '10px';
    }
  });

  document.querySelectorAll('.scale-table th').forEach((cell) => {
    if (cell.textContent.trim() === 'Harm Min') {
      cell.style.borderBottomLeftRadius = '10px';
    }
  });

  const [loading, setLoading] = useState(true);

  const Loader = () => {
    return (
      <div className="loader-container">
        <h1 className="title">ChordProg</h1> {/* Añadido className="title" */}
        <div className="loader"></div>
      </div>
    );
};

  useEffect(() => {
    // Simula una carga de datos con un retraso
    const timer = setTimeout(() => {
      setLoading(false); // Esto oculta el Loader después de 3 segundos
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  function bpmLabel() {
    return (
      <label htmlFor="bpm-input" className="bpm-label">BPM:</label>
    );
  }
  
  const TutorialTab = ({ changeTab, toggleMenu, isMenuVisible, menuIconRef, menuRef, dropdownPosition, handleCloseMenu }) => {
    
    const handleBackgroundClick = (e) => {
      e.stopPropagation();
    };
    
    return (
      <div className="App">
        <div className="header">
          <div className="title-and-menu-icon">
            <h1 className="tutorial-title">ChordProg</h1>
          </div>
        </div>
        <div className='subtitle-container'><h2 className="subtitle">Discover the power of ChordProg by diving into our 3 minutes tutorial. Mastering its intricacies is key to unlocking a world of musical possibilities. Don't miss out on this essential step towards unleashing your creativity!</h2></div>
        <div className="tutorial-content">
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1Eb4BxPdHS8?si=8_VQgtVfrM-FfzkT"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <button className="use-chordprog" onClick={() => changeTab('Main')}>Continue to ChordProg</button>
        </div>
      </div>
    );
  };

  return (
    <div className="App">

      {loading ? (
        <Loader />
      ) : (
        // Aquí va el contenido principal de tu app cuando no está cargando
        <div>
          {activeTab === 'Tutorial' ? (
        <TutorialTab
          changeTab={changeTab}
          toggleMenu={toggleMenu}
          isMenuVisible={isMenuVisible}
          menuIconRef={menuIconRef}
          menuRef={menuRef}
          dropdownPosition={dropdownPosition}
          handleCloseMenu={handleCloseMenu}
      />
      ) : (
        <>
          <div className="header">
            <div className="title-and-menu-icon">
              <h1 className="title">ChordProg</h1>
              {/* Ícono del menú que, al hacer clic, abrirá el menú de Material UI */}
              <div className="menu-icon" onClick={handleMenuClick}>☰</div>
            </div>
            {/* Menú de Material UI */}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                className: "dropdown-menu", // Asegúrate de que esta clase CSS esté definida correctamente en tu CSS
                style: { width: 'max-content', margin: '0 auto' } // Ajusta el ancho del menú y lo centra horizontalmente si es necesario
              }}
            >
              <MenuItem onClick={() => { changeTab('Tutorial'); handleClose(); }} style={{ justifyContent: 'center' }}>
                Tutorial
              </MenuItem>
              <MenuItem style={{ justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <span
                    onClick={() => { handleNotationChange('DoReMi'); handleClose(); }}
                    style={{
                      color: notationType === 'DoReMi' ? '#297FBA' : '#BCBCAF',
                      fontWeight: notationType === 'DoReMi' ? 'bold' : 'normal',
                      cursor: 'pointer',
                      display: 'inline-block'
                    }}
                    className="menu-option-hover"
                  >
                    Do
                  </span>
                  <span>|</span>
                  <span
                    onClick={() => { handleNotationChange('CDE'); handleClose(); }}
                    style={{
                      color: notationType === 'CDE' ? '#297FBA' : '#BCBCAF',
                      fontWeight: notationType === 'CDE' ? 'bold' : 'normal',
                      cursor: 'pointer',
                      display: 'inline-block'
                    }}
                    className="menu-option-hover"
                  >
                    C
                  </span>
                </div>
              </MenuItem>
              <MenuItem style={{ justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <span
                    onClick={() => { toggleTheme('dark'); handleClose(); }}
                    style={{
                      color: theme === 'dark' ? '#297FBA' : '#BCBCAF',
                      fontWeight: theme === 'dark' ? 'bold' : 'normal',
                      cursor: 'pointer',
                      display: 'inline-block'
                    }}
                    className="menu-option-hover"
                  >
                    Dark
                  </span>
                  <span>|</span>
                  <span
                    onClick={() => { toggleTheme('light'); handleClose(); }}
                    style={{
                      color: theme === 'light' ? '#297FBA' : '#BCBCAF',
                      fontWeight: theme === 'light' ? 'bold' : 'normal',
                      cursor: 'pointer',
                      display: 'inline-block'
                    }}
                    className="menu-option-hover"
                  >
                    Light
                  </span>
                </div>
              </MenuItem>
              <div className="bpm-container"> {/* Contenedor agregado para agrupar BPM: y el input */}
                <label htmlFor="bpm-input" className="bpm-label">BPM:</label>
                <input
                  id="bpm-input" // Asegúrate de que el htmlFor del label y el id del input coincidan
                  className="bpm-input"
                  type="number"
                  min="60"
                  max="240"
                  value={bpm} // bpm es el estado que almacena el valor del BPM
                  onChange={(e) => setBpm(e.target.value)} // setBpm es el método para actualizar el estado del BPM
                />
              </div>
              <MenuItem onClick={() => { openContactPopup(); handleClose(); }} style={{ justifyContent: 'center' }}>
                Contact
              </MenuItem>
              <MenuItem onClick={handleClose} style={{ justifyContent: 'center' }}>
                Close
              </MenuItem>
            </Menu>

          </div>
    
          <div className="table-container">
            <table className="chord-table">
              <thead>
                <tr>
                  <th className="chords-cell">CHORDS</th> {/* Empty cell in the corner */}
                  {notes.map((note) => (
                    <th key={note}>{notationType === 'CDE' ? mapNotation(note) : note}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{generateTableRows("chord")}</tbody>
            </table>
          </div>
    
          <div className="bar-and-trash-container">
  <button
    className="save-button"
    disabled={isSaveDisabled}
    onClick={handleSaveClick}
  >
    💾
  </button>
  <div className="drop-bar" onDrop={handleDrop} onDragOver={handleDragOver}>
    <button className="play-button" onClick={handlePlayClick}>Play</button>
    {droppedChords.map((chordObj, index) => (
      <div
        key={index}
        className={`dropped-chord ${isPlaying && index === playBarPosition ? 'active-chord' : ''}`}
        draggable
        onDragStart={(e) => handleDragStartOnBar(e, chordObj.internalName)}
        onDrop={handleDropOnBar}
        onDragOver={handleDragOver}
      >
        {chordObj.displayName}
      </div>
    ))}
  </div>
  <div className="trash-bin" onDrop={handleDropOnTrashBin} onDragOver={handleDragOver}>
    🗑️
  </div>
</div>

    
          <div className="guitar-chords-container">
            {guitarChordImages.map((imgSrc, index) => {
              const chordObj = droppedChords[index]; // Obtiene el objeto de acorde correspondiente
              return (
                <div key={index} className="chord-container">
                  <div className="chord-name">{chordObj.displayName}</div> {/* Muestra el nombre del acorde usando displayName */}
                  <img src={imgSrc} alt={`Guitar Chord: ${chordObj.displayName}`} /> {/* Usa displayName para el texto alternativo */}
                </div>
              );
            })}
          </div>
    
          <div className="piano-chords-container">
            {pianoChordImages.map((imgSrc, index) => {
              // Asegúrate de que el índice usado aquí coincide con el del acorde en droppedChords
              const chordObj = droppedChords[index];
              return (
                <div key={index} className="chord-container">
                  <div className="chord-name">{chordObj.displayName}</div> {/* Usa displayName para mostrar al usuario */}
                  <img src={imgSrc} alt={`Piano Chord: ${chordObj.displayName}`} /> {/* Usa displayName para el texto alternativo */}
                </div>
              );
            })}
          </div>
    
          <div className="table-container">
            <table className="scale-table">
              <thead>
                <tr>
                  <th className="scales-cell">SCALES</th> {/* Empty cell in the corner */}
                  {notes.map((note) => (
                    <th key={note}>{notationType === 'CDE' ? mapNotation(note) : note}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{generateTableRows("scale")}</tbody>
            </table>
          </div>
    
          <Menu
            keepMounted
            open={contextMenu.visible}
            onClose={closeContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu.mouseY !== null && contextMenu.mouseX !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            MenuListProps={{
              className: "context-menu" // Aplicar tu clase CSS aquí
            }}
          >
            <MenuItem onClick={() => { selectedNote.play(); closeContextMenu(); }}>Play</MenuItem>
            <MenuItem disabled>Notes: {selectedNote.notes}</MenuItem>
            {selectedNote.type === "chord" && (
              <MenuItem onClick={() => { selectedNote.addToBar(selectedNote.name); closeContextMenu(); }}>Add to bar</MenuItem>
            )}
            <MenuItem onClick={closeContextMenu}>Close</MenuItem>
          </Menu>
    
          {saveMenu.visible && (
            <div
              className="save-menu"
              style={{ top: `${saveMenu.y}px`, left: `${saveMenu.x}px` }}
              onClick={handleSaveMenuClick}
            >
              <ul>
                {saveOptions.map((option, index) => (
                  <li key={index} onClick={option.action}>
                    {option.label}{option.check ? " ✅" : ""}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {isContactPopupVisible && (
            <div className="contact-popup-overlay" onClick={closeContactPopup}>
              <div className="contact-popup" onClick={(e) => e.stopPropagation()}>
                <div className="contact-popup-content">
                  <span className="close-popup" onClick={closeContactPopup}>×</span>
                  <h2>Contact</h2>
                  <p><br/>
                    Thank you for reaching out! We're always here to help.
                    If you have any questions, suggestions, or need assistance, please don't hesitate to contact us.
                    You can reach us directly via email at:<br/><br/>
                    contact@chordprog.com<br/><br/>
                    We strive to respond to all inquiries as swiftly as possible and look forward to hearing from you.
                  </p>
                </div>
              </div>
            </div>
          )}

        </>
      )}
        </div>
      )}

      
    </div>
  );  
};

export default App;